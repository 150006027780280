import React from "react";
import { StyleSheet, css } from "aphrodite";
import { CprButton } from "canopy-styleguide!sofe";
export default class StepFour extends React.Component {
  render() {
    return (
      <div className={`${css(styles.body)}`}>
        <div>
          <img
            className={`${css(styles.img)}`}
            src={`https://cdn.canopytax.com/static/licenses/thumb-up-outline.svg`}
          />
        </div>
        <div className={`cps-subheader-sm cps-wt-bold`}>Invite sent, you're all set!</div>
        <div className={`cps-margin-bottom-24`}>The person you just invited should receive an email shortly.</div>
        <div>
          <CprButton onClick={this.props.close} actionType={"primary"}>
            Finish
          </CprButton>
          <CprButton onClick={this.props.restart} actionType={"secondary"}>
            Add another
          </CprButton>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  body: {
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    height: "88px",
    marginBottom: "8px",
  },
});
