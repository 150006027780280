import React from "react";
import { StyleSheet, css } from "aphrodite";
import { CprButton } from "canopy-styleguide!sofe";

export default class Footer extends React.Component {
  state = {
    renderNothing: false,
  };
  render() {
    if (this.state.renderNothing) {
      return null;
    }

    const sendInvitationStep = 2;

    return (
      <div className={`${css(styles.footer)} ${this.props.activeStep !== 0 ? css(styles.withBackButton) : ""}`}>
        {this.props.activeStep !== 0 && (
          <CprButton actionType="flat" onClick={this.props.back} className={`cps-link`}>
            <span className="cps-icon cps-icon-arrow-left" />
            Back
          </CprButton>
        )}
        <CprButton
          actionType="primary"
          disabled={!this.props.isValid}
          disableOnClick={true}
          showLoader={this.props.sending}
          onClick={this.props.next}
        >
          {sendInvitationStep === this.props.activeStep ? "Send invitation" : "Next step"}
        </CprButton>
      </div>
    );
  }
  componentDidUpdate(prevProps) {
    /* Until IE11 support is gone, there is a weird bug in the styleguide where things break
       if you change the text of button and then try to show a loader on it. Instead of fixing that
       bug, I'm working around it since IE11 support will be gone in the next week or so.
       To work around, I'm destroying the buttons and recreating them every time the step changes,
       which is also when the text changes. Once https://code.canopy.ninja/front-end/blue/canopy-styleguide/merge_requests/258
       is merged, we can delete this entirely.
    */
    if (this.props.activeStep !== prevProps.activeStep) {
      this.setState({ renderNothing: true }, () => {
        this.setState({ renderNothing: false });
      });
    }
  }
}

const styles = StyleSheet.create({
  footer: {
    padding: "16px 24px",
    display: "flex",
    borderRadius: "0 0 1px 1px",
    justifyContent: "flex-end",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "var(--cps-color-silver)",
  },
  withBackButton: {
    justifyContent: "space-between",
  },
});
